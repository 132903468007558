export default function InputField({
                                       label,
                                       placeholder,
                                       onChange,
                                       prefix,
                                       suffix,
                                       extraClass = '',
                                       type = 'text',
                                       labelColor = 'text-black',
                                       paddingY = 'py-4',
                                       paddingX = 'px-2'
                                   }) {
    return (
        <div>
            <label className={`${labelColor}`} htmlFor={'email'}>{label}</label>
            <div className={`border border-black rounded flex items-center ${paddingY} ${paddingX} ${extraClass} mt-2`}>
                {prefix}
                <div className={'flex flex-1'}>
                    <input
                        type={type} onChange={onChange}
                        placeholder={placeholder}
                        className={`w-full outline-none ${labelColor}`}/>
                </div>
                {suffix}
            </div>
        </div>
    )
}