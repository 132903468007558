import {Player} from "@lottiefiles/react-lottie-player";

export default function Loading() {
    return (
        <div className={'h-screen w-screen flex justify-center items-center'}>
            <Player
                autoplay
                loop
                src="https://assets8.lottiefiles.com/private_files/lf30_fx0qink0.json"
                style={{height: '300px', width: '300px'}}/>
        </div>
    )
}