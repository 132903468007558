import axios from "axios";
import {LocalStorageKeys} from "../../global/config/asset_constants";
import {LoggerServices} from "./logger_service";

const {ServerConfig} = require("../../global/config/server_config");


let getHeader = (fbTkn, type = 'application/json') => {
    let header = {
        'Content-Type': type,
    }
    header.authorization = localStorage.getItem(fbTkn ? LocalStorageKeys.fb_tkn : LocalStorageKeys.node_tkn)

    return header
}

const getConfig = (method, url, header, form) => (
    method === 'POST'
        ? {
            method: method,
            url: url,
            headers: header,
            data: form
        }
        : {
            method: method,
            url: url,
            headers: header
        }
)


const apiFormCall = async (path, form, fbTkn = false) => {

    let url;

    url = `${ServerConfig.API_LINK}${path}`

    let config = getConfig(
        'POST',
        url,
        getHeader(fbTkn, 'multipart/form-data'),
        form
    );
    // LoggerServices.basicLog('api-config: ', config)

    return await axios(config).catch((reason) => {
        // ServerError()
        LoggerServices.basicLog(path, reason);
        LoggerServices.basicLog("status: ", reason.response.status);
        return {
            status: reason.response.status,
            message: reason
        }
    });
}

const apiJsonCall = async (path, form, fbTkn = false) => {

    let url;
    url = `${ServerConfig.API_LINK}${path}`

    let config = getConfig(
        'POST',
        url,
        getHeader(fbTkn),
        form
    );
    // LoggerServices.basicLog('api-config: ', config)

    return await axios(config).catch((reason) => {
        // ServerError()
        LoggerServices.basicLog(path, reason);
        LoggerServices.basicLog("status: ", reason.response.status);
        return {
            status: reason.response.status,
            message: reason
        }
    });
}

const apiGetCall = async (path, fbTkn = false) => {

    let url;
    url = `${ServerConfig.API_LINK}${path}`

    let config = getConfig(
        'GET',
        url,
        getHeader(fbTkn)
    );

    return await axios(config).catch((reason) => {
        // ServerError()
        LoggerServices.basicLog(path, reason);
        LoggerServices.basicLog("status: ", reason.response.status);
        return {
            status: reason.response.status,
            message: reason
        }
    });
}

export const ApiServices = {
    apiJsonCall,
    apiFormCall,
    apiGetCall
}