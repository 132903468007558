import {useContext, useState} from "react";
import NavMenu from "./nav_menu";
import {AuthenticatedUserContext} from "../../../providers/authentication";
import {Images} from "../../../global/config/asset_constants";
import ProfileImage from "../profile_image";

export default function UserNavbar({children, padding = 'pt-5'}) {
    const {user} = useContext(AuthenticatedUserContext);
    const [nav, setNav] = useState({
        showNav: false
    });
    console.log('user: ', user)
    return (
        <div className={'flex min-h-screen'}>
            <div
                className={`bg-gradient-to-tr from-menu-gradient-start to-menu-gradient-end flex flex-col items-stretch transition-all duration-400 absolute inset-y-0 left-0 md:relative md:translate-x-0 w-64 ${nav.showNav ? 'md:w-64' : '-translate-x-full md:w-20'}`}>
                <div className={'pt-5 items-stretch flex justify-center'}>
                    <img
                        className={`transition-all object-contain ${nav.showNav ? 'w-28 h-16' : 'w-16 h-16'}`}
                        src={nav.showNav ? Images.logo_white_name : Images.logo_square_white} alt="Logo"/>
                </div>
                <NavMenu showMenu={nav.showNav}/>
            </div>
            <div onClick={() => setNav({...nav, showNav: !nav.showNav})}
                 className={`absolute md:block hidden top-24 cursor-pointer transition-all duration-400 ${nav.showNav ? 'left-60 origin-center -rotate-180' : 'left-14'}`}>
                <div className={'w-10 h-10 bg-gray-50 border border-black rounded-full'}>
                    <svg fill="currentColor" className="w-10 h-10" viewBox="0 0 17 17">
                        <path fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </div>
            </div>
            <div className={`flex-auto`}>
                <div
                    className={`md:hidden transition-all duration-400 opacity-70 absolute inset-0 left-64 ${nav.showNav ? '' : 'hidden'}`}
                    onClick={() => setNav({...nav, showNav: !nav.showNav})}/>
                <div className={'flex justify-between bg-blue-9502'}>
                    <div className={'md:hidden cursor-pointer w-fit p-4'}
                         onClick={() => setNav({...nav, showNav: !nav.showNav})}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="w-8 h-8" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </div>
                    <ProfileImage
                        image={user.data.profile}
                        customClass={'mt-4 mr-4 md:hidden'}/>
                </div>
                <div className={`${padding} pl-5`}>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    )
}