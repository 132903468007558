import React, {createContext, useState} from 'react';

export const AuthenticatedUserContext = createContext({
    data: {},
    profileComplete: {},
    isAuthenticated: false,
});

export const AuthenticatedUserProvider = ({children}) => {
    const [user, setUser] = useState({
        data: {},
        profileComplete: {},
        isAuthenticated: false,
    });

    return (
        <AuthenticatedUserContext.Provider value={{user, setUser}}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
};