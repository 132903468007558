import {Navigate, Outlet} from 'react-router-dom'
import {AuthenticatedUserContext} from "../../providers/authentication";
import {useContext, useEffect, useState} from "react";
import {AuthServices} from "../../services/auth_services";
import Loading from "../../components/global/loading";
import {LoggerServices} from "../../services/helpers/logger_service";

const PrivateRoutes = () => {
    const {user, setUser} = useContext(AuthenticatedUserContext);
    const [wrapState, setWrapState] = useState({
        isLoading: true
    });

    useEffect(() => {
        LoggerServices.basicLog('running check...')
        AuthServices.checkSession()
            .then((data) => {
                LoggerServices.basicLog('user auth: ', data.isAuthenticated)
                if (data.isAuthenticated)
                    setUser({
                        ...user,
                        isAuthenticated: data.isAuthenticated,
                        data: data.userData.data,
                        token: data.userData.token,
                    })
                else
                    setUser({
                        ...user,
                        isAuthenticated: data.isAuthenticated
                    })
                setWrapState({
                    ...wrapState,
                    isLoading: false
                })
            })
            .catch(() => {
                AuthServices.signOut().then(() => {
                    setUser({
                        ...user,
                        isAuthenticated: false
                    })
                })
            })
    }, [])

    if (wrapState.isLoading)
        return <Loading/>

    return (
        user.isAuthenticated ? <Outlet/> : <Navigate to="/login" replace/>
    )
}

export default PrivateRoutes;