import React from 'react';
import {AuthenticatedUserProvider} from "./providers/authentication";
import {RouterProvider} from "react-router-dom";
import IndexRoute from "./routes/index_route";

function App() {
    return (
        <>
            <AuthenticatedUserProvider>
                <RouterProvider router={IndexRoute}/>
            </AuthenticatedUserProvider>
        </>
    );
}

export default App;
