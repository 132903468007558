import {useState} from "react";
import {sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import InputField from "../global/input_field";
import CustomButton from "../global/custom_button";

export default function SignIn({auth, state, setState}) {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const [signIn, setSignIn] = useState({
        email: '',
        password: '',
        error: ''
    });

    const signInAccount = () => {
        signInWithEmailAndPassword(auth, signIn.email, signIn.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("user: ", user)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('error: ', errorCode)
                console.log('error: ', errorMessage)
                setSignIn({
                    ...signIn,
                    error: 'Email or password is wrong'
                })
                setState({
                    ...state,
                    loading: false,
                });
            });
    }

    const forgotPassword = () => {
        sendPasswordResetEmail(auth, signIn.email)
            .then(() => {
                setSignIn({
                    ...signIn,
                    error: `Password reset mail sent to ${signIn.email}`
                })
                setState({
                    ...state,
                    loading: false
                });
            })
    }

    const checkForm = () => {
        setState({
            ...state,
            loading: true
        });
        if (signIn.email !== '') {
            if (reg.test(signIn.email)) {
                if (signIn.password !== '') {
                    signInAccount();
                    return setSignIn({
                        ...signIn,
                        error: ''
                    })
                } else {
                    setSignIn({
                        ...signIn,
                        error: 'Password cannot be empty'
                    });
                }
            } else {
                setSignIn({
                    ...signIn,
                    error: 'Email entered is invalid'
                });
            }
        } else {
            setSignIn({
                ...signIn,
                error: 'Enter an email'
            });
        }
        setState({
            ...state,
            loading: false
        });
    }

    const checkEmail = () => {
        setState({
            ...state,
            loading: true
        });
        if (signIn.email !== '') {
            if (reg.test(signIn.email)) {
                return forgotPassword();
            } else {
                setSignIn({
                    ...signIn,
                    error: 'Email entered is invalid'
                });
            }
        } else {
            setSignIn({
                ...signIn,
                error: 'Enter an email'
            });
        }
        setState({
            ...state,
            loading: false
        });
    }

    return (
        <form className={'flex flex-col space-y-4 mt-8'}>
            <InputField
                label={'Email'} autocomplete={"username"}
                placeholder={'Enter an email'}
                onChange={(e) => setSignIn({
                    ...signIn,
                    email: e.target.value,
                })}/>
            <InputField
                label={'Password'} type={'password'}
                autocomplete={"current-password"} placeholder={'Enter your password'}
                onChange={(e) => setSignIn({
                    ...signIn,
                    password: e.target.value,
                })}/>
            <span className={'text-center text-red-500'}>{signIn.error}</span>
            <div className={'flex flex-1 justify-end'}>
                <span
                    className={'text-blue-500 cursor-pointer hover:text-blue-700'}
                    onClick={(e) => {
                        e.preventDefault();
                        checkEmail();
                    }}>Forgot password?</span>
            </div>
            <CustomButton
                buttonText={'Sign In'} padding={'py-4'} loading={state.loading}
                onPress={() => {
                    checkForm();
                }}/>
        </form>
    )
}