const releaseType = {
    PRODUCTION: 'production',
    STAGING: 'staging',
    DEVELOPMENT: 'development',
}

const DevConfig = {
    API_LINK: 'http://localhost:8000/v1/api',
    WEB_LINK: 'http://localhost:8000',
    RELEASE_TYPE: releaseType.DEVELOPMENT,
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyCirIwRrc7r7bx1fsTYZnFgYBh_tfzVXIw",
        authDomain: "ar-web-stage.firebaseapp.com",
        projectId: "ar-web-stage",
        storageBucket: "ar-web-stage.appspot.com",
        messagingSenderId: "1077055442681",
        appId: "1:1077055442681:web:810f6305ea91c75f549c28",
        measurementId: "G-CWBS0V5Y6M"
    }
}

const StageConfig = {
    API_LINK: 'https://api.craftric.in/v1/api',
    WEB_LINK: 'https://api.craftric.in',
    RELEASE_TYPE: releaseType.STAGING,
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyCirIwRrc7r7bx1fsTYZnFgYBh_tfzVXIw",
        authDomain: "ar-web-stage.firebaseapp.com",
        projectId: "ar-web-stage",
        storageBucket: "ar-web-stage.appspot.com",
        messagingSenderId: "1077055442681",
        appId: "1:1077055442681:web:810f6305ea91c75f549c28",
        measurementId: "G-CWBS0V5Y6M"
    }
}

const ProdConfig = {
    API_LINK: 'https://api.craftric.in/v1/api',
    WEB_LINK: 'https://api.craftric.in',
    RELEASE_TYPE: releaseType.PRODUCTION,
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyD1nSb1_IY5tUmRN1qwmDhbBsbSa8kaa2w",
        authDomain: "ar-web-58079.firebaseapp.com",
        projectId: "ar-web-58079",
        storageBucket: "ar-web-58079.appspot.com",
        messagingSenderId: "703834172937",
        appId: "1:703834172937:web:43cb173dd18b81a29f12d8",
        measurementId: "G-22NJBNYYXJ"
    }
}

const getServerConfig = (releaseConfig) => {
    if (releaseConfig === releaseType.PRODUCTION)
        return ProdConfig;
    else if (releaseConfig === releaseType.STAGING)
        return StageConfig;
    else if (releaseConfig === releaseType.DEVELOPMENT)
        return DevConfig;
}

const ServerConfig = getServerConfig(releaseType.PRODUCTION);
module.exports = {
    ServerConfig,
    releaseType
}