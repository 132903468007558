export default function ProfileImage({image, customClass = ''}) {
    return (
        <div className={`relative group rounded-full ${customClass}`}>
            <img className={'self-center w-10 h-10 object-cover object-center rounded-full'}
                 src={image}
                 alt="profile_img"/>
            <div
                className={'absolute hidden group-hover:block opacity-0 group-hover:opacity-100 transition-all duration-500 -right-4 md:right-0 md:w-44 w-screen bg-pink-200'}>
                test text
            </div>
        </div>
    )
}