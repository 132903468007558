import {Navigate, Outlet, useLocation} from "react-router-dom";
import {initFirebase} from "../../global/firebase/firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {LocalStorageKeys} from "../../global/config/asset_constants";

export default function RootLayout() {
    const location = useLocation()

    let app = initFirebase();
    const gAuth = getAuth(app);

    onAuthStateChanged(gAuth, (user) => {
        if (user) {
            // LoggerServices.basicLog('user: ', user)
            localStorage.setItem(LocalStorageKeys.fb_tkn, user['accessToken'])
        } else {
        }
    });


    return (
        <div>
            {(location.pathname === '/') ? <Navigate to={'dashboard'} replace/> : <Outlet/>}
        </div>
    )
}