import {Images} from "../../global/config/asset_constants";
import Authenticate from "../../components/login/authenticate";
import {Navigate} from "react-router-dom";
import {AuthenticatedUserContext} from "../../providers/authentication";
import {useContext} from "react";

export default function LoginScreen() {
    const {user} = useContext(AuthenticatedUserContext);

    if (user.isAuthenticated)
        return <Navigate to="/dashboard" replace/>;

    return (
        <>
            <main className={'h-screen w-screen'}>
                <div className={'h-screen flex flex-col lg:flex-row'}>

                    {/*left part*/}
                    <div className={'lg:w-[40%] px-8 md:px-28 lg:px-8 h-screen flex flex-col justify-center'}>
                        <img className={'w-32 mb-12 lg:hidden'} src={Images.logo_black_name} alt="logo"/>
                        <Authenticate/>
                    </div>

                    {/*right part*/}
                    <div
                        className={'relative hidden lg:block lg:w-[60%] bg-blue-50'}
                        style={{
                            backgroundImage: `url(${Images.building_bg})`,
                            backgroundSize: 'cover'
                        }}>
                        <div
                            className={'absolute inset-0 bg-black opacity-20 flex flex-col justify-center items-center'}/>
                        <div
                            className={'absolute inset-0 z-10 flex flex-col justify-center items-center'}>
                            <img className={'w-64'} src={Images.logo_white_name} alt="logo"/>
                            <h3 className={'text-white text-2xl text-center pt-8'}>Revolutionize the way interior
                                designers<br/>
                                create and present their designs to clients</h3>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}