import {useContext, useEffect} from "react";
import {LoggerServices} from "../../services/helpers/logger_service";
import {AuthenticatedUserContext} from "../../providers/authentication";
import UserNavbar from "../../components/nav/nav_bar";
import Header from "../../components/dashboard/header";

const Dashboard = () => {
    const {user} = useContext(AuthenticatedUserContext);

    useEffect(() => {
        LoggerServices.basicLog('dash log: ', user);
    }, [])

    return (
        <UserNavbar padding={''}>
            <Header name={user.data['first_name']} image={user.data.profile}/>
            
        </UserNavbar>
    )
}

export default Dashboard;