import {useContext, useEffect, useState} from "react";
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {initFirebase} from "../../global/firebase/firebase";
import SignIn from "./signin";
import SignUp from "./signup";
import {Images, LocalStorageKeys} from "../../global/config/asset_constants";
import {AuthenticatedUserContext} from "../../providers/authentication";
import {ApiServices} from "../../services/helpers/api_services";

export default function Authenticate() {
    const {user, setUser} = useContext(AuthenticatedUserContext);
    let app = initFirebase();
    const gAuth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const [auth, setAuth] = useState({
        isLogin: true,
        loading: false
    });
    const [fbUser, loading] = useAuthState(gAuth);

    const signInUser = async (fbUser) => {
        let response = await ApiServices.apiGetCall('/user/signin', true)
        console.log('user data from node: ', {
            ...user,
            isAuthenticated: true,
            data: response.data.data,
            token: response.data.token,
        })
        localStorage.setItem(LocalStorageKeys.node_tkn, response.data.token)
        setUser({
            ...user,
            isAuthenticated: true,
            data: response.data.data,
            token: response.data.token,
        });
    }

    const gSignIn = async () => {
        const result = await signInWithPopup(gAuth, provider);
        console.log(result.user);
        localStorage.setItem(LocalStorageKeys.fb_tkn, result.user['accessToken'])
    };

    useEffect(() => {
        if (!loading) {
            if (fbUser) {
                signInUser(fbUser)
                    .then(() => {
                        setAuth({
                            ...auth,
                            loading: false
                        })
                        // router.push("/dashboard");
                    })
                    .catch((e) => {
                        console.log(e)
                        setAuth({
                            ...auth,
                            loading: false
                        })
                    });
            }
        }
    }, [fbUser])

    return (
        <div>
            <h3 className={'text-3xl pb-4 font-medium'}>{auth.isLogin ? 'Login' : 'Sign Up'}</h3>
            <p className={''}>
                Continue with google or {auth.isLogin ? 'Login' : 'Get started'} with email
            </p>
            <p className={''}>
                {auth.isLogin ? 'New here?' : 'Already have an account?'} <span
                className={'text-blue-500 cursor-pointer hover:text-blue-700'}
                onClick={() => setAuth({
                    isLogin: !auth.isLogin,
                })}>{auth.isLogin ? 'Click here to Sign Up' : 'Click here'}</span>
            </p>
            {
                auth.isLogin
                    ? <SignIn state={auth} setState={setAuth} auth={gAuth}/>
                    : <SignUp state={auth} setState={setAuth} auth={gAuth}/>
            }
            <div className={'flex items-center my-4'}>
                <div className={'flex flex-1 h-px bg-primary'}/>
                <span className={'mx-2'}>OR</span>
                <div className={'flex flex-1 h-px bg-primary'}/>
            </div>
            <button onClick={gSignIn}
                    className={`w-full flex items-center justify-center space-x-3 border border-primary rounded-lg py-4`}>
                <img className={'w-8'} src={Images.google_logo} alt="Google logo"/>
                <span className={'text-primary '}>Continue with Google</span>
            </button>
        </div>
    )
}