import {useState} from "react";
import {createUserWithEmailAndPassword} from "firebase/auth";
import InputField from "../global/input_field";
import CustomButton from "../global/custom_button";

export default function SignUp({auth, state, setState}) {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const [signIn, setSignIn] = useState({
        email: '',
        password: '',
        confirm_password: '',
        error: ''
    });

    const createAccount = () => {
        createUserWithEmailAndPassword(auth, signIn.email, signIn.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("user: ", user)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('error: ', errorCode)
                console.log('error: ', errorMessage)
                setState({
                    ...state,
                    loading: false
                });
            });
    }

    const checkForm = () => {
        setState({
            ...state,
            loading: true
        });
        if (signIn.email !== '') {
            if (reg.test(signIn.email)) {
                if (signIn.password !== '') {
                    if (signIn.confirm_password === signIn.password) {
                        createAccount();
                        return setSignIn({
                            ...signIn,
                            error: ''
                        })
                    } else {
                        setSignIn({
                            ...signIn,
                            error: 'Password does not match'
                        });
                    }
                } else {
                    setSignIn({
                        ...signIn,
                        error: 'Password cannot be empty'
                    });
                }
            } else {
                setSignIn({
                    ...signIn,
                    error: 'Email entered is invalid'
                });
            }
        } else {
            setSignIn({
                ...signIn,
                error: 'Enter an email'
            });
        }
        setState({
            ...state,
            loading: false
        });
    }

    return (
        <form className={'flex flex-col space-y-4 mt-8'}>
            <InputField
                label={'Email'} placeholder={'Enter an email'}
                autocomplete={"username"}
                onChange={(e) => setSignIn({
                    ...signIn,
                    email: e.target.value,
                })}/>
            <InputField
                label={'Password'} type={'password'}
                autocomplete={"current-password"}
                placeholder={'Enter your password'}
                onChange={(e) => setSignIn({
                    ...signIn,
                    password: e.target.value,
                })}/>
            <InputField
                label={'Confirm Password'} type={'password'}
                placeholder={'Re-enter your password '}
                onChange={(e) => setSignIn({
                    ...signIn,
                    confirm_password: e.target.value,
                })}/>
            <span className={'text-center text-red-500'}>{signIn.error}</span>
            <CustomButton
                buttonText={'Sign Up'} padding={'py-4'} loading={state.loading}
                onPress={() => {
                    checkForm();
                }}/>
        </form>
    )
}