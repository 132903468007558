import {Player} from "@lottiefiles/react-lottie-player";
import {Lottie} from "../../global/config/asset_constants";

export default function CustomButton({
                                         borderRadius = 'rounded-lg',
                                         buttonText = 'Custom Button',
                                         onPress,
                                         padding = 'py-4',
                                         loading = false,
                                     }) {
    return (
        <button onClick={(e) => {
            e.preventDefault();
            onPress()
        }} className={`bg-primary ${padding} ${borderRadius}`}>
            {loading
                ? <div className={'-my-3'}><Player
                    autoplay loop
                    src={Lottie.loading}
                    style={{height: '60px', width: '60px'}}/></div>
                : <span className={'text-white'}>{buttonText}</span>
            }
        </button>
    )
}