// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {ServerConfig} from "../config/server_config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = ServerConfig.FIREBASE_CONFIG;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Export function to initialize firebase.
export const initFirebase = () => {
    return app;
};