import {useNavigate} from "react-router-dom";

export default function NavButton({showMenu, text, icon, path}) {
    const navigate = useNavigate();
    return (
        <button
            title={text} onClick={() => navigate(path)}
            className={`flex px-3 space-x-2 items-center py-4 my-1 transition-all duration-300 hover:bg-gray-900 ${showMenu ? '' : 'justify-center'}`}>
            <div className={'w-8 h-8 justify-center flex'}>
                {icon}
            </div>
            {showMenu
                ? <span className={'whitespace-nowrap text-white'}>{text}</span>
                : <div/>
            }
        </button>
    )
}