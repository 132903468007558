import {ApiServices} from "./helpers/api_services";
import {LocalStorageKeys} from "../global/config/asset_constants";
import moment from "moment";

const resetSession = async () => {
    let resp = await ApiServices.apiGetCall('/user/reset/session', true);
    let user = resp.data

    if (resp.status === 200)
        return {
            isAuthenticated: true,
            data: user.data,
            token: user.token,
        }

    return {
        isAuthenticated: false
    };


}

const checkSession = async () => {
    let resp = await ApiServices.apiGetCall('/user/check/session');
    if (resp.status === 200) {
        localStorage.setItem(LocalStorageKeys.auth_time, moment().toISOString())
        let data = resp.data.data;
        let message = resp.data.message;
        if (data === 'session/active')
            return {
                isAuthenticated: true,
                userData: {
                    isAuthenticated: true,
                    data: message.data,
                    token: message.token,
                }
            };
        else {
            let newData = await resetSession();
            if (newData.isAuthenticated)
                return {
                    isAuthenticated: true,
                    userData: {
                        isAuthenticated: true,
                        data: newData.data,
                        token: newData.token,
                    }
                };
            else
                return {
                    isAuthenticated: false,
                };
        }
    } else
        return {
            isAuthenticated: false,
        };
}

const signOut = async () => {

}

export const AuthServices = {
    checkSession,
    signOut,
}