import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import LoginScreen from "../screens/auth/login";
import RootLayout from "../screens/global/root_layout";
import NotFound from "../screens/global/not_found";
import PrivateRoutes from "../screens/global/auth_wrap";
import Dashboard from "../screens/home/dashboard";

const IndexRoute = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout/>}>
            <Route element={<PrivateRoutes/>}>
                <Route element={<Dashboard/>} path={'dashboard'}/>
            </Route>
            <Route path="login" element={<LoginScreen/>}/>
            <Route path={'*'} element={<NotFound/>}/>
        </Route>
    )
);

export default IndexRoute;