const favicon = require('../../assets/favicon.ico');
const logo_white_name = require('../../assets/images/logo/logo_white_name.png');
const logo_black_name = require('../../assets/images/logo/logo_black_name.png');
const logo_square_white = require('../../assets/images/logo/logo_square_white.png');
const logo_square_black = require('../../assets/images/logo/logo_square_black.png');
const building_bg = require('../../assets/images/login/building_bg.jpeg');
const google_logo = require('../../assets/images/login/google_logo.png');
const loading = require('../../assets/lottie/loading.json');

export const Images = {
    favicon: favicon,
    logo_white_name: logo_white_name,
    logo_black_name: logo_black_name,
    logo_square_white: logo_square_white,
    logo_square_black: logo_square_black,
    building_bg: building_bg,
    google_logo: google_logo,
};

export const Lottie = {
    loading: loading
}

export const LocalStorageKeys = {
    auth: 'user_data',
    fb_tkn: 'firebase_token',
    node_tkn: 'node_token',
    auth_time: 'auth_time',
}