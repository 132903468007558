import moment from "moment";
import ProfileImage from "../nav/profile_image";

export default function Header({name = '', image}) {
    return (
        <div className={'h-16 flex justify-between items-center px-4'}>
            <span>{moment().get('hours') < 12
                ? 'Good Morning'
                : ((moment().get('hours') >= 12) && (moment().get('hours') < 4))
                    ? 'Good Afternoon'
                    : 'Good Evening'}, {name}</span>
            <div className={'hidden md:inline'}>
                <div className={'bg-white rounded-full'}>
                    <ProfileImage
                        image={image}/>
                </div>
            </div>
        </div>
    )
}